import { MainLogo } from "../assets";
import SocialRow from "../items/SocialRow";
import "../styles/Footer.css";

const Footer = () => {
	const currentYear = new Date().getFullYear();
	return (
		<div className="footer">
			<div className="logoContainer">
				<img src={MainLogo} className="logo" alt="Sobremesa logo" />
			</div>
			<p className="copyright">
				&copy; {currentYear} Sobremesa <br /> Todos los derechos
				reservados <br /> Prohibida su distribución para fines
				maliciosos
			</p>
			<div className="link-container">
				<a
					target="_blank"
					className="link-ref"
					href="https://firebasestorage.googleapis.com/v0/b/sobremesagastro.appspot.com/o/politicas%2Fpolitica-de-tratamiento-de-la-informacion-datos-personales%20Sobremesa.pdf?alt=media&token=bdccb56e-a916-4154-8300-1fdb516ad175"
				>
					Política de privacidad
				</a>
				<a
					target="_blank"
					className="link-ref"
					href="https://firebasestorage.googleapis.com/v0/b/sobremesagastro.appspot.com/o/politicas%2Fterminos-y-condiciones%20Sobremesa.pdf?alt=media&token=bdf0eea4-7bd8-4639-bfe3-509fddbf75f1"
				>
					Términos y condiciones
				</a>
			</div>
			<SocialRow />
			<p className="copyright">
				Diseñado y desarrollado por
				<span>
					{" "}
					<a
						className="link-ref"
						href="https://survivorslabs.com/"
						target="__blank"
					>
						Survivors Labs
					</a>
				</span>
			</p>
		</div>
	);
};

export default Footer;
