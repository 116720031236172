import { UP } from "../assets";
import About from "../components/About";
import Banner from "../components/Banner";
import Clients from "../components/Clients";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Proyects from "../components/Proyects";
import Services from "../components/Services";
import Team from "../components/Team";
import { Element } from "react-scroll";

const Home = () => {
	return (
		<>
			{/* <Element name="banner" id="banner" className="section"> */}
			<Banner />
			{/* </Element> */}
			{/* <Element name="about" id="banner" className="section"> */}
			<About />
			{/* </Element> */}
			{/* <Element name="proyects" id="banner" className="section"> */}
			<Services />
			{/* </Element> */}
			{/* <Element name="clients" id="banner" className="section"> */}
			<Clients />
			{/* </Element> */}
			{/* <Element name="proyects" id="banner" className="section"> */}
			<Proyects />
			{/* </Element> */}
			{/* <Element name="contact" id="banner" className="section"> */}
			<ContactForm />
			{/* </Element> */}
			{/* <Element name="team" id="banner" className="section"> */}
			{/* <Team /> */}
			{/* </Element> */}
			<footer>
				<Footer />
			</footer>
		</>
	);
};

export default Home;
