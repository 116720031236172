import React, { useState, useEffect } from "react";
import "../styles/NavBar.css";
import { isMobile } from "react-device-detect";
import { UP } from "../assets";

const items = [
	{ title: "Acerca de", href: "#acerca-de" },
	{ title: "Servicios", href: "#servicios" },
	{ title: "Clientes", href: "#clientes" },
	{ title: "Proyectos", href: "#proyectos" },
	{ title: "Contacto", href: "#contacto" },
];

const NavBar = () => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [activeSection, setActiveSection] = useState("");
	const [top, setTop] = useState<string>("20px");
	const [bottom, setBottom] = useState<string>("auto");

	useEffect(() => {
		const handleScroll = () => {
			setScrollPosition(window.scrollY);
			const sections = items
				.map((item) => document.querySelector(item.href))
				.filter(Boolean);

			for (const section of sections) {
				if (section) {
					const rect = section.getBoundingClientRect();
					const topBoundary = rect.top + window.scrollY - 150;
					const bottomBoundary = rect.bottom + window.scrollY - 150;

					if (
						scrollPosition >= topBoundary &&
						scrollPosition <= bottomBoundary
					) {
						setActiveSection(section.id);
						break;
					}
				}
			}
		};

		window.addEventListener("scroll", handleScroll);

		//Animacion
		if (scrollPosition < (isMobile ? 180 : 400)) {
			setBottom("auto");
			setTop("30px");
		}
		if (scrollPosition > (isMobile ? 800 : 1300)) {
			setBottom("40px");
			setTop("auto");
		}

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollPosition]);

	const getLinkClassName = (href: string) => {
		const id = href.split("#")[1];
		return `link-nav ${activeSection === id ? "active" : ""}`;
	};

	const navStyle = {
		top: top,
		bottom: bottom,
	};

	return (
		<div
			className={`nav-container animate__animated ${
				scrollPosition < (isMobile ? 180 : 400) ||
				scrollPosition > (isMobile ? 800 : 1300)
					? "animate__fadeIn"
					: "animate__fadeOut"
			}`}
			style={navStyle}
		>
			{scrollPosition > 800 && (
				<div className={"item-arrow"}>
					<a href="#banner">
						<img src={UP} className="back-arrow" />
					</a>
				</div>
			)}
			<div className="navbar">
				<div className="items-nav">
					{items.map((item, index) => (
						<div key={index} className="item-nav">
							<a
								className={getLinkClassName(item.href)}
								href={item.href}
							>
								{item.title}
							</a>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default NavBar;
