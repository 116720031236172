import {
	getFirestore,
	collection,
	getDocs,
	query,
	where,
	deleteDoc,
	doc,
	setDoc,
	updateDoc,
	limit as firestoreLimit,
	DocumentData,
	orderBy,
} from "firebase/firestore";
import {
	getStorage,
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";
import { useState } from "react";
import app from "../../firebaseConfig";
import { getCurrentDate } from "../utils/helpers";
import { IQuery } from "../types/CommonTypes";

export default function useFirebase() {
	const [loading, setLoading] = useState<boolean>(false);
	const db = getFirestore(app);

	const createTransaction = (path: string) => {
		const db = getFirestore(app);
		const transaction = doc(collection(db, path));
		return transaction.id;
	};

	const createDocument = async (
		data: any,
		collectionName: string,
		callback: (res: boolean, idDoc?: string) => void
	) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			const newDocRef = doc(collection(db, collectionName));
			const result = await setDoc(newDocRef, {
				id: newDocRef.id,
				date: getCurrentDate(),
				...data,
			});
			callback(true, newDocRef.id);
		} catch (error) {
			console.log("No se pudo crear el documento", error);
			callback(false);
		}
	};

	const updateDocument = async (
		data: any,
		path: string,
		callback: (res: boolean) => void
	) => {
		try {
			setLoading(true);
			const docRef = doc(db, path);
			await updateDoc(docRef, { ...data });
			callback(true);
		} catch (error) {
			setLoading(false);
			callback(false);
			console.log("Error updating blog", error);
		}
	};

	const uploadPicture = async (
		path: string,
		picture: any,
		callback: (res: boolean, url: string) => void
	) => {
		try {
			const storage = getStorage();
			const storageRef = ref(storage, path);
			uploadBytes(storageRef, picture).then((snapshot) => {
				getDownloadURL(storageRef)
					.then((link) => callback(true, link))
					.catch((error) => {
						console.log("No se obtener el link de descarga", error);
						callback(false, "");
					});
			});
		} catch (error) {
			console.log("Error subiendo la imagen", error);
			callback(false, "");
		}
	};

	const deletePicture = async (path: string, callback: any) => {
		const storage = getStorage(app);
		const fileRef = ref(storage, path);

		deleteObject(fileRef)
			.then(() => {
				console.log("Archivo eliminado con éxito");
				callback(true);
			})
			.catch((error) => {
				console.error("Error al eliminar el archivo:", error);
				callback(false);
			});
	};

	const getData = async (path: string, callback: any) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			const ref = collection(db, path);
			const snap = await getDocs(ref);
			const snapList = snap.docs.map((doc) => doc.data());
			setLoading(false);
			callback(snapList);
		} catch (error) {
			console.log("Error trayendo servicios", error);
			setLoading(false);

			callback(undefined);
		}
	};

	const getDocument = async (
		path: string,
		paramName: string,
		paramValue: string,
		callback: any
	) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			const q = query(
				collection(db, path),
				where(paramName, "==", paramValue)
			);
			const querySnapshot = await getDocs(q);
			let blogs: any[] = [];
			querySnapshot.docs.map((doc) => blogs.push(doc.data()));
			setLoading(false);
			callback(blogs);
		} catch (error) {
			console.log("Error trayendo blog por id", error);
			setLoading(false);
		}
	};

	const getCollection = async (path: string, options?: IQuery) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			let collectionQuery: any = collection(db, path);
			const {
				limit,
				filters,
				orderByField = "date",
				orderDirection = "desc",
			} = options || {};

			let queryConstraints = [];

			// Añadir ordenamiento
			queryConstraints.push(orderBy(orderByField, orderDirection));

			// Añadir limite si existe
			if (limit) {
				queryConstraints.push(firestoreLimit(limit));
			}

			// Añadir filtros dinámicamente
			if (filters) {
				Object.entries(filters).forEach(([field, value]) => {
					queryConstraints.push(where(field, "==", value));
				});
			}

			// Construir la consulta con los constraints acumulados
			if (queryConstraints.length) {
				collectionQuery = query(collectionQuery, ...queryConstraints);
			}

			const querySnapshot = await getDocs(collectionQuery);
			const docs: any[] = [];
			querySnapshot.forEach((doc) => {
				docs.push({ id: doc.id, ...(doc.data() as DocumentData) });
			});

			setLoading(false);
			return docs;
		} catch (error) {
			console.log("Error trayendo la colección", error);
			setLoading(false);
			return [];
		}
	};

	const deleteDocumentById = async (
		path: string,
		id: string,
		callback: (res: boolean) => void,
		picturePath?: any
	) => {
		try {
			setLoading(true);
			if (picturePath) {
				deletePicture(picturePath, async function (succes: boolean) {
					const db = getFirestore(app);
					if (succes) {
						let response = await deleteDoc(doc(db, path, id));
						callback(true);
						setLoading(false);
					} else {
						callback(false);
					}
				});
			} else {
				const db = getFirestore(app);
				let response = await deleteDoc(doc(db, path, id));
				callback(true);
				setLoading(false);
			}
		} catch (error) {
			console.log("Error eliminando documento", error);
			setLoading(false);
			callback(false);
		}
	};

	return {
		getData,
		getDocument,
		deleteDocumentById,
		createDocument,
		uploadPicture,
		createTransaction,
		updateDocument,
		getCollection,
		deletePicture,
		loading,
	};
}
