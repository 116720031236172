import "../styles/Services.css";
import { IService } from "../types/CommonTypes";

const ServiceCard = ({ title, description, src }: IService) => {
	return (
		<div className="item">
			<div className="picture-container">
				<img src={src} className="picture-card" />
			</div>
			<div className="content">
				<h2 className="title-services">{title}</h2>
				<p className="description-services">{description}</p>
			</div>
		</div>
	);
};

export default ServiceCard;
