import * as Yup from "yup";

const contactScheme = Yup.object().shape({
	name: Yup.string().required("El nombre es requerido"),
	lastName: Yup.string().required("El apellido es requerido"),
	email: Yup.string()
		.email("Introduce un email válido")
		.required("El email es requerido"),
	phoneNumber: Yup.string()
		.min(11, "Introduce un número de celular válido")
		.required("El celular es requerido"),

	description: Yup.string().required(
		"Introduce un mensaje para poder contactarte"
	),
});

export { contactScheme };
