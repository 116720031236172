// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "../styles/Swipper.css";

// import required modules
import { EffectCards, Autoplay } from "swiper/modules";
import { ICard } from "../types/CommonTypes";

interface IProps {
	cards: ICard[];
	onChange: (index: number) => void;
}

export default function SwipperCards({ cards, onChange }: IProps) {
	return (
		<>
			<Swiper
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}}
				effect={"cards"}
				grabCursor={true}
				modules={[EffectCards, Autoplay]}
				className="mySwiper"
				onSlideChange={(item: any) => onChange(item.activeIndex)}
			>
				{cards.map((item, index) => (
					<SwiperSlide key={index}>{item.component}</SwiperSlide>
				))}
			</Swiper>
		</>
	);
}
