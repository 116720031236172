import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyBRhDYlf1u8c7BWwVoF0JEGDxvQjugK0fY",
	authDomain: "sobremesa-agencia.firebaseapp.com",
	projectId: "sobremesa-agencia",
	storageBucket: "sobremesa-agencia.appspot.com",
	messagingSenderId: "716472622993",
	appId: "1:716472622993:web:2207096e9f9a38966fc430",
	measurementId: "G-9Q1L4S67G7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
