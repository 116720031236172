import { useForm } from "react-hook-form";
import "../styles/ContactForm.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactScheme } from "../commons/resolvers";
import SocialRow from "../items/SocialRow";
import { useInView } from "react-intersection-observer";
import useContact, { IContact } from "../hooks/useContact";
import { getCurrentDate } from "../utils/helpers";
import Loader from "../items/Loader";

const ContactForm = () => {
	const { ref, inView } = useInView({ threshold: 0.3 });
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			name: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			description: "",
		},
		mode: "onSubmit",
		resolver: yupResolver(contactScheme),
	});

	const { createForm, loading } = useContact();

	return (
		<div ref={ref} className="container-form" id="contacto">
			<div
				className={`header animate__animated ${
					inView
						? "animate__slideInBottom"
						: "animate__slideOutBottom"
				}`}
			>
				<h1 className="title title-header">¡Hablemos!</h1>
				<p className="description description-header">
					Puedes contactarnos a través de este formulario. Alguien de
					nuestro equipo se pondrá en contacto contigo
				</p>
			</div>
			<div className="social">
				<h3>También nos puedes contactar por</h3>
				<SocialRow />
			</div>
			<div
				className={`form-inner animate__animated ${
					inView ? "animate__zoomIn" : "animate__zoomOut"
				}`}
			>
				<form
					onSubmit={handleSubmit(async (values) => {
						await createForm({ ...values, date: getCurrentDate() });
						reset();
					})}
					className="form"
					noValidate
				>
					<h2 className="title-form">Formulario de contacto</h2>
					<p className="message">
						Deja tus datos y nos pondremos en contacto.{" "}
					</p>
					<div className="flex-input">
						<label>
							<input
								{...register("name")}
								className="input"
								type="text"
								required
								maxLength={50}
							/>
							<span>Nombres</span>
							{errors.name && (
								<p className="error">{errors.name?.message}</p>
							)}
						</label>
						<label>
							<input
								{...register("lastName")}
								className="input"
								type="text"
								required
								maxLength={50}
							/>
							<span>Apellidos</span>
							{errors.lastName && (
								<p className="error">
									{errors.lastName?.message}
								</p>
							)}
						</label>
					</div>

					<label>
						<input
							{...register("phoneNumber")}
							className="input"
							type="text"
							required
							maxLength={11}
						/>
						<span>Celular</span>
						{errors.phoneNumber && (
							<p className="error">
								{errors.phoneNumber?.message}
							</p>
						)}
					</label>
					<label>
						<input
							{...register("email")}
							className="input"
							type="email"
							required
							maxLength={150}
						/>
						<span>Correo Electrónico</span>
						{errors.email && (
							<p className="error">{errors.email?.message}</p>
						)}
					</label>

					<label>
						<textarea
							{...register("description")}
							className="input text-area"
							required
							rows={3}
							maxLength={5000}
						/>
						<span>Mensaje</span>
						{errors.description && (
							<p className="error">
								{errors.description?.message}
							</p>
						)}
					</label>
					{loading || isSubmitting ? (
						<Loader />
					) : (
						<div className="btn">
							<button
								disabled={loading || isSubmitting}
								className="submit"
							>
								Enviar
							</button>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default ContactForm;
