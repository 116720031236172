import { clients } from "../commons/constants";
import "../styles/Clients.css";
import theme from "../utils/theme";

const Clients = () => {
	return (
		<div className="container-clients" id="clientes">
			<h1
				style={{ color: theme.colors.secondary }}
				className="title-clients"
			>
				<span>Nuestros Clientes</span>
			</h1>
			<div
				className="scroller"
				data-direction="left"
				data-speed="fast"
				data-animated="true"
			>
				<div className="scroller__inner">
					{clients.map((item, index) => (
						<div key={index} className="tag">
							{item}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Clients;
