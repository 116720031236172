import {
	ExpoEstudiantes,
	Gastro,
	Libro,
	Levapan,
	CaffaLogo,
	GraziaLogo,
	MinEduLogo,
	LevapanLogo,
	RevistaLogo,
	SanJorgeLogo,
	DislicoresLogo,
	GastroMujeresLogo,
	InstitucionalLogo,
	UniagustinianaLogo,
} from "../assets";
import { ICard } from "../types/CommonTypes";

const Image = ({ src }: any) => {
	return (
		<img
			style={{ objectFit: "fill" }}
			src={src}
			width={"100%"}
			height={"auto"}
		/>
	);
};

const cards: ICard[] = [
	{
		index: 0,
		organization: "Ministerio de educación",
		event: "Expo estudiante",
		description:
			"Creación y realización del material gráfico y audiovisual que se presentó durante la feria Expo Estudiantes del Ministerio de Educación en el año 2023",
		component: <Image src={ExpoEstudiantes} />,
	},
	{
		index: 1,
		organization: "Uniagustiniana",
		event: "Libro Tubérculos Andinos",
		description:
			"Toma de fotografías para el libro Tubérculos Andinos, el cual resultó ganador de los Premios Gourmand, en el año 2022, como uno de los mejores cuatro libros de cocina del mundo publicados por editoriales universitarias",
		component: <Image src={Libro} />,
	},
	{
		index: 2,
		organization: "Levapan",
		event: "Creación de contenido y manejo de redes sociales",
		description:
			"Creación de contenidos, estrategias de comunicación digital y manejo de redes sociales para la marca Levapan en Colombia, Republica Dominicana y Ecuador.",
		component: <Image src={Levapan} />,
	},
	{
		index: 3,
		organization: "Gastromujeres",
		event: "Creación de contenidos y gestión de evento",
		description:
			"Gestión para el desarrollo del primer congreso de mujeres y Gastronomía en Colombia y desarrollo de contenidos para las redes sociales.",
		component: <Image src={Gastro} />,
	},
];

const clients: any[] = [
	<Image src={CaffaLogo} />,
	<Image src={GraziaLogo} />,
	<Image src={MinEduLogo} />,
	<Image src={LevapanLogo} />,
	<Image src={RevistaLogo} />,
	<Image src={SanJorgeLogo} />,
	<Image src={DislicoresLogo} />,
	<Image src={GastroMujeresLogo} />,
	<Image src={InstitucionalLogo} />,
	<Image src={UniagustinianaLogo} />,
	<Image src={CaffaLogo} />,
	<Image src={GraziaLogo} />,
	<Image src={MinEduLogo} />,
	<Image src={LevapanLogo} />,
	<Image src={RevistaLogo} />,
	<Image src={SanJorgeLogo} />,
	<Image src={DislicoresLogo} />,
	<Image src={GastroMujeresLogo} />,
	<Image src={InstitucionalLogo} />,
	<Image src={UniagustinianaLogo} />,
];

export { cards, clients };
