const BannerImg = require("./banner2.png");
const BannerResponsive = require("./bannerResponsive.png");
const Apple3D = require("./3dIcons/apple.glb");
const AboutImg = require("./brand/about.jpg");

//CARDS
// const ExpoEstudiantes = require("./cards/expo.jpeg");
// const Gastro = require("./cards/gastro.jpeg");
// const Levapan = require("./cards/levapan.png");
// const Libro = require("./cards/libro.jpg");
const ExpoEstudiantes = require("./proyects/educacion.png");
const Gastro = require("./proyects/gastro.jpg");
const Levapan = require("./proyects/redes.png");
const Libro = require("./proyects/libro.jpg");

//LOGOS
const MainLogo = require("./brand/logo-blanco.png");
const MainLogoDark = require("./brand/logo-negro.png");

const CaffaLogo = require("./logos/Caffa.png");
const DislicoresLogo = require("./logos/dislicores.png");
const GastroMujeresLogo = require("./logos/GastroMujeres.png");
const GraziaLogo = require("./logos/Grazia.png");
const InstitucionalLogo = require("./logos/Institucional Colombia.jpg");
const LevapanLogo = require("./logos/Levapan.jpg");
const MinEduLogo = require("./logos/Ministerio_de_Educación_de_Colombia.png");
const RevistaLogo = require("./logos/Revista Pan Caliente.png");
const SanJorgeLogo = require("./logos/San Jorge Ma.png");
const UniagustinianaLogo = require("./logos/Uniagustiniana.png");

//AVATAR
const Avatar1 = require("./team/avatar1.png");
const Avatar2 = require("./team/avatar2.png");
const Avatar3 = require("./team/avatar3.png");
const Avatar4 = require("./team/avatar4.png");
const Avatar5 = require("./team/avatar5.jpeg");
const Avatar6 = require("./team/avatar6.png");
const Avatar7 = require("./team/avatar7.jpeg");

//SERVICES

const Service1 = require("./services//Service1.jpeg");
const Service2 = require("./services/Service2.jpg");
const Service3 = require("./services/Service3.jpg");
const Service4 = require("./services/Service4.jpg");

const UP = require("./brand/up.png");

export {
	BannerImg,
	Apple3D,
	AboutImg,
	ExpoEstudiantes,
	Gastro,
	Levapan,
	Libro,
	CaffaLogo,
	DislicoresLogo,
	GastroMujeresLogo,
	GraziaLogo,
	InstitucionalLogo,
	LevapanLogo,
	MinEduLogo,
	RevistaLogo,
	SanJorgeLogo,
	UniagustinianaLogo,
	Avatar1,
	Avatar2,
	Avatar3,
	Avatar4,
	Avatar5,
	Avatar6,
	Avatar7,
	Service1,
	Service2,
	Service3,
	Service4,
	MainLogo,
	UP,
	MainLogoDark,
	BannerResponsive,
};
