import { useState } from "react";
import { cards } from "../commons/constants";
import SwipperCards from "../items/SwipperCards";
import "../styles/Proyects.css";
import { useInView } from "react-intersection-observer";

const Proyects = () => {
	const [selectedProyect, setSelectedProyect] = useState<number>(0);
	const { ref, inView } = useInView({ threshold: 0.3 });

	return (
		<div ref={ref} className="container-proyects" id="proyectos">
			<span></span>

			<div className="main-container">
				<div
					key={selectedProyect}
					className={`textContainer animate__animated ${
						inView ? "animate__fadeIn" : "animate__fadeOut"
					}`}
				>
					<h1 className="title">
						{cards[selectedProyect].organization}
					</h1>
					<h1 className="subtitle">
						{" "}
						{cards[selectedProyect].event}
					</h1>
					<p className="description-proyect">
						{cards[selectedProyect].description}
					</p>
				</div>
			</div>
			<div
				className={`cards animate__animated ${
					inView ? "animate__zoomIn" : "animate__zoomOut"
				}`}
			>
				<div className="shape"></div>
				<SwipperCards
					cards={cards}
					onChange={(index: number) => setSelectedProyect(index)}
				/>
			</div>
		</div>
	);
};

export default Proyects;
