import { useState } from "react";
import { toast } from "react-toastify";
import { getCurrentDate } from "../utils/helpers";
import useFirebase from "./useFirebase";

export interface IContact {
	id?: string;
	date?: string;
	name: string;
	lastName: string;
	phoneNumber: string;
	description: string;
	email: string;
}

export const initialState: IContact = {
	name: "",
	lastName: "",
	phoneNumber: "",
	description: "",
	email: "",
};

const useContact = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { createDocument, getCollection, deleteDocumentById } = useFirebase();
	const [contacts, setContacts] = useState<IContact[]>([]);

	const createForm = async (values: IContact) => {
		setLoading(true);
		const bodyContact = { ...values, date: getCurrentDate() };
		const result = await createDocument(
			bodyContact,
			"contacts",
			(res: boolean) => {
				if (res) toast.success("Nos pondremos en contácto muy pronto!");
				else toast.error("Ups! Hubo un error al guardar tus datos");
			}
		);
		setLoading(false);
	};

	const getContacts = async (limit: number) => {
		setLoading(true);
		const queryOptions = { limit: limit };
		const contacts = await getCollection("contacts", queryOptions);
		setContacts(contacts);
		setLoading(false);
	};

	const deleteContact = async (id: string) => {
		await deleteDocumentById(`contacts`, id, (res) => {
			if (res) {
				setContacts(contacts.filter((item) => item.id !== id));
				toast.success("Registro eliminado con exito");
			} else toast.error("No pudimos eliminar el documento");
		});
	};

	return {
		createForm,
		getContacts,
		deleteContact,
		contacts,
		loading,
		initialState,
	};
};

export default useContact;
