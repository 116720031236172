import { FloatingWhatsApp } from "react-floating-whatsapp";
import Home from "./app/pages/Home";
import "animate.css";
import NavBar from "./app/components/NavBar";
import app from "./firebaseConfig";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainLogo } from "./app/assets";

function App() {
	return (
		<>
			<FloatingWhatsApp
				phoneNumber={"573054358827"}
				accountName={"Sobremesa"}
				statusMessage="En linea"
				chatMessage="Hola, me encanta tenerte aqui ¿Cómo te puedo ayudar?"
				avatar={MainLogo} //Cambiar el icono por el de sobremesa
				allowEsc
				notification
				notificationDelay={5000}
				placeholder="Deja tu mensaje..."
			/>

			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				transition={Bounce}
			/>
			<div style={{ position: "relative" }}>
				<NavBar />
				<Home />
			</div>
		</>
	);
}

export default App;
