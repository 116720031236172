import { useEffect, useState } from "react";
import "../styles/About.css";
import { AboutImg } from "../assets";
import { useInView } from "react-intersection-observer";

const About = () => {
	const TEXTS = ["Historias", "Experiencias", "Tradiciones"];
	const [currentText, setCurrentText] = useState<number>(0);
	const interval = 1500;
	const { inView, ref } = useInView({ threshold: 0.3 });

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentText((currentText) => (currentText + 1) % TEXTS.length);
		}, interval);

		return () => clearInterval(timer);
	}, [TEXTS.length, interval]);
	return (
		<div ref={ref} className="container-about" id="acerca-de">
			<div className="leftSide center column">
				<h1
					className={`text rotating-text animate__animated ${
						inView && "animate__slideInLeft"
					}`}
				>
					Contenidos que <br />
					cuentan
					<span>{` ${TEXTS[currentText]}`}</span>
				</h1>
				<p
					className={`description animate__animated ${
						inView && "animate__slideInUp"
					}`}
				>
					Al igual que en una deliciosa receta de cocina, preparamos
					contenidos audiovisuales y escritos combinando bien los
					ingredientes para dar origen a buenas historias y utilizamos
					las herramientas adecuadas para producirlas.
				</p>
			</div>
			<div
				className={`rightSide animate__animated ${
					inView ? "animate__zoomIn" : "animate__zoomOut"
				}`}
			>
				<img
					className="picture"
					src={AboutImg}
					width={"100%"}
					height={"auto"}
				/>
			</div>
		</div>
	);
};

export default About;
