import { useInView } from "react-intersection-observer";
import { Service1, Service2, Service3, Service4 } from "../assets";
import ServiceCard from "../items/ServiceCard";
import "../styles/Services.css";
import "../styles/Button.css";
import { IService } from "../types/CommonTypes";

const services: IService[] = [
	{
		title: "Fotografía de producto",
		description:
			"Nos encargamos de que tus productos luzcan irresistibles en catálogos, páginas web, aplicaciones y demás piezas publicitarias",
		src: Service1,
	},
	{
		title: "Fotografía de alimentos",
		description:
			"Haciendo uso de técnicas de maquillaje de alimentos y food styling, capturamos el lado mas provocativo y estético de la comida",
		src: Service2,
	},
	{
		title: "Creación de videos, piezas gráficas y animaciones",
		description:
			"Contenidos audiovisuales acorde a la identidad y el relato de tu marca",
		src: Service3,
	},
	{
		title: "Desarrollo de artículos para revistas, periódicos y más",
		description:
			"Capturamos la atención de tus lectores a través de textos únicos con información de valor utilizando diversos estilos de escritura",
		src: Service4,
	},
];

const Services = () => {
	return (
		<div className="container-services" id="servicios">
			<span></span>
			<div className="grid">
				{services.map((service, index) => (
					<AnimatedServiceCard
						key={index}
						title={service.title}
						description={service.description}
						src={service.src}
						delay={index * 0.1} // Ajuste el valor del retraso según necesites
					/>
				))}
			</div>
			<button
				onClick={() =>
					window.open("https://www.behance.net/sobremesac445c")
				}
				className="btn-services"
			>
				Nuestro Portafolio
			</button>
		</div>
	);
};

const AnimatedServiceCard = ({ title, description, src, delay }: any) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<div
			ref={ref}
			style={{ animationDelay: `${delay}s` }}
			className={`animate__animated ${
				inView ? "animate__zoomIn" : "animate__zoomOut"
			}`}
		>
			<ServiceCard title={title} description={description} src={src} />
		</div>
	);
};

export default Services;
