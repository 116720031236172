import { isMobile } from "react-device-detect";
import { BannerImg, BannerResponsive, MainLogo } from "../assets";
import "../styles/Banner.css";

const Banner = () => {
	return (
		<div className="container " id="banner">
			<div className="logo-container">
				<img src={MainLogo} alt="Logo sobremesa" className="logo" />
			</div>
			<img
				className="picture-banner"
				src={isMobile ? BannerResponsive : BannerImg}
				style={
					isMobile
						? {
								paddingTop: "50px",
						  }
						: {}
				}
			/>
		</div>
	);
};

export default Banner;
