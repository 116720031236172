const theme = {
	colors: {
		primary: "#000",
		secondary: "#009898",
		third: "#fda900",
		orange: "#fd7300",
		purple: "#9e62d2",
		red: "#ff2e21",
	},
};

export default theme;
